import React from 'react';
import { Link } from "react-router-dom";
import GradientBackground from './GradientBackground';
import { useTranslation } from 'react-i18next';

function Hero() {
  const { t } = useTranslation();

  return (
    <div className="relative isolate px-6 pt-14 lg:px-8">
      <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80">
        <svg className="relative left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem]" viewBox="0 0 1155 678">
          <path fill="url(#45de2b6b-92d5-4d68-a6a0-9b9b2abad533)" fillOpacity=".3" d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z" />
          <defs>
            <linearGradient id="45de2b6b-92d5-4d68-a6a0-9b9b2abad533" x1="1155.49" x2="-78.208" y1=".177" y2="474.645" gradientUnits="userSpaceOnUse">
              <stop stopColor="#9089FC" />
              <stop offset="1" stopColor="#FF80B5" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className="mx-auto max-w-2xl py-32 sm:py-32 lg:py-32">
        <div className="hidden sm:mb-8 sm:flex sm:justify-center">
        <Link
          to="/contacts"
          className="relative rounded-full py-1 px-3 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20"
        >
          {t('hero-contact-us')}{' '}
          <span className="font-semibold text-indigo-600">
            <span className="absolute inset-0" aria-hidden="true"></span>{t('hero-redirect')}{' '}
            <span aria-hidden="true">&rarr;</span>
          </span>
        </Link>
        </div>
        <div className="text-center">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">{t('hero-title')}</h1>
          <p className="mt-6 text-lg leading-8 text-gray-600">{t('hero-text')}</p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link to="/tracking" className="text-sm font-semibold leading-6 text-gray-900">
              {t('hero-tracking')} <span aria-hidden="true">&rarr;</span>
            </Link>
          </div>
        </div>
      </div>
      <GradientBackground />
    </div>
  );
}

export default Hero;
