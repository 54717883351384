import React from 'react';
import { useTranslation } from 'react-i18next';

export function DefinitionListItem({ label, value }) {
  return (
    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
      <dt className="text-sm font-medium text-gray-500">{label}</dt>
      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{value}</dd>
    </div>
  );
}

export default function WaybillDetails({ info }) {
  const { t } = useTranslation();
  const {
    code = '',
    receiver = '',
    origin_region = '',
    destination_region = '',
    parcels = '',
    weight = '',
    quantity = '',
  } = info;
  
  return (
    <div className="container mx-auto sm:px-6 lg:px-8">
      <div className="overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-base font-semibold leading-6 text-gray-900">{t('waybill-details-waybill')} {code}</h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">{t('waybill-details-title')}</p>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <DefinitionListItem label={t('waybill-details-receiver-name')} value={receiver} />
            <DefinitionListItem label={t('waybill-details-origin-region')} value={origin_region} />
            <DefinitionListItem label={t('waybill-details-destination-region')} value={destination_region} />
            <DefinitionListItem label={t('waybill-details-parcels')} value={parcels} />
            <DefinitionListItem label={t('waybill-details-weight')} value={`${weight} кг`} />
            <DefinitionListItem label={t('waybill-details-quantity')} value={quantity} />
          </dl>
        </div>
      </div>
    </div>
  )
}