import React from 'react';
import { useTranslation } from 'react-i18next';

export function DeliveryStatus({ code }) {
  const statusColor = code.includes('success') ? 'bg-green-500' : 'bg-red-500';
  const statusText = code.includes('success') ? 'Успешная доставка' : 'Неудачная попытка доставки';
  return (
    <span className={`px-2 py-1 text-sm font-semibold text-white rounded-md ${statusColor}`}>
      {statusText}
    </span>
  );
}

export function DeliveryListItem({ code, stamp, code_description, receiver_name, receiver_position }) {
  const { t } = useTranslation();
  const date = new Date(stamp);
  const formattedDate = `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
  
  return (
    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
      <dt className="text-sm font-medium text-gray-500">{t('delivery-details-date-time')}</dt>
      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{formattedDate}</dd>

      <dt className="text-sm font-medium text-gray-500">{t('delivery-details-description')}</dt>
      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{code_description}</dd>

      {code.includes('success') && (
        <>
          <dt className="text-sm font-medium text-gray-500">{t('delivery-details-receiver-name')}</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{receiver_name || '-'}</dd>

          <dt className="text-sm font-medium text-gray-500">{t('delivery-details-receiver-position')}</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{receiver_position || '-'}</dd>
        </>
      )}
    </div>
  );
}

export default function DeliveryDetails({ deliveries }) {
  const sortedDeliveries = deliveries ? deliveries.sort((a, b) => new Date(b.stamp) - new Date(a.stamp)) : [];

  return (
    <div className="container mx-auto sm:px-6 lg:px-8 mt-5">
      {sortedDeliveries.map((delivery, index) => (
        <div
          key={index}
          className={`mb-4 overflow-hidden bg-white shadow sm:rounded-lg ${
            delivery.code.includes('success') ? 'border-t-4 border-green-500' : 'border-t-4 border-red-500'
          }`}
        >
          <div className="px-4 py-5 sm:px-6">
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              <DeliveryStatus code={delivery.code} />
            </p>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
            <dl className="sm:divide-y sm:divide-gray-200">
              <DeliveryListItem {...delivery} />
            </dl>
          </div>
        </div>
      ))}
    </div>
  );
}
