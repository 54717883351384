import React, { useState, useEffect } from 'react';
import { BookmarkIcon, CheckIcon, UserIcon, TruckIcon, PlusIcon, ClipboardDocumentCheckIcon, ChevronDoubleUpIcon, FlagIcon, BellAlertIcon } from '@heroicons/react/20/solid';

export const iconMapping = {
  'DA': { icon: ChevronDoubleUpIcon, color: 'bg-blue-500' }, // Arrived
  'DC': { icon: PlusIcon, color: 'bg-green-500' }, // Registered
  'DD': { icon: TruckIcon, color: 'bg-blue-500' }, // Dispatched
  'DN': { icon: ChevronDoubleUpIcon, color: 'bg-blue-500' }, // Packed In
  'DO': { icon: UserIcon, color: 'bg-blue-500' }, // Packed Out
  'DR': { icon: BookmarkIcon, color: 'bg-blue-500' }, // Draft
  'DS': { icon: CheckIcon, color: 'bg-green-500' }, // Delivered
  'DF': { icon: FlagIcon, color: 'bg-red-500' }, // Failed
  'DP': { icon: ClipboardDocumentCheckIcon, color: 'bg-green-500' }, // Processed
  // add more mappings as needed here
};

export function createTimeline(tracks, iconMapping) {
  return tracks.map((track, trackIdx) => {
    const trackDate = new Date(track.created_at);
    const formattedDate = `${trackDate.getDate().toString().padStart(2, '0')}-${(trackDate.getMonth()+1).toString().padStart(2, '0')}-${trackDate.getFullYear().toString().slice(-2)} ${trackDate.getHours().toString().padStart(2, '0')}:${trackDate.getMinutes().toString().padStart(2, '0')}`;
  
    return {
      id: trackIdx,
      content: track.description,
      target: track.branch,
      href: '#',
      created_at: formattedDate,
      icon: iconMapping[track.code]?.icon || BellAlertIcon,
      iconBackground: iconMapping[track.code]?.color || 'bg-gray-400',
    };
  });
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function TrackingFeed({ tracks }) {
  const [timeline, setTimeline] = useState([]);

  useEffect(() => {
    setTimeline(createTimeline(tracks, iconMapping));
  }, [tracks]);

  return (
    <div className="flow-root mt-10">
      <ul className="-mb-8">
        {timeline.map((event, eventIdx) => (
          <li key={event.id}>
            <div className="relative pb-8">
              {eventIdx !== timeline.length - 1 ? (
                <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
              ) : null}
              <div className="relative flex space-x-3">
                <div>
                  <span
                    className={classNames(
                      event.iconBackground,
                      'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                    )}
                  >
                    <event.icon className="h-5 w-5 text-white" aria-hidden="true" />
                  </span>
                </div>
                <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                  <div>
                    <p className="text-sm text-gray-500">
                      {event.content}{' '}
                      <br />
                      <a href={event.href} className="font-medium text-gray-900">
                        {event.target}
                      </a>
                    </p>
                  </div>
                  <div className="whitespace-nowrap text-right text-sm text-gray-500">
                    <time dateTime={event.created_at}>{event.created_at}</time>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
