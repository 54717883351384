import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Hero from './components/Hero';
import NotFound from './components/NotFound';
import Tracking from './pages/Tracking';
import Contacts from './pages/Contacts';
import PrivacyAgreement from './pages/PrivacyAgreement';
import "./App.css"

function App() {
  return (
    <div data-testid="app-component">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Hero />} />
          <Route path="/tracking" element={<Tracking />} />
          <Route path="/tracking/:inputData" element={<Tracking />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path='*' element={<NotFound />}/>
          <Route path="/privacyAgreement" element={<PrivacyAgreement />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
