
import { BuildingOffice2Icon, EnvelopeIcon, PhoneIcon, ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/outline';
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import emailjs from '@emailjs/browser';
import InputMask from 'react-input-mask';
import { useTranslation } from 'react-i18next';
import CookieBanner from '../components/CookieBanner';
import ErrorMessage from "../components/ErrorMessage";
import SuccessMessage from '../components/SuccessMessage';

export default function Contacts() {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false); // add state variable for loading status
  const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;
  const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const form = useRef();

  const handlePhoneNumberInput = (event) => {
    const input = event.target.value;
    const inputDigits = input.replace(/\D/g, "");

    const formattedPhoneNumber = inputDigits.replace(
      /^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/,
      "+$1($2)$3-$4-$5"
    );

    setPhoneNumber(formattedPhoneNumber);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (form.current.checkValidity() && isChecked) { // add isChecked to the condition
      setIsLoading(true); // set loading to true before sending email
      emailjs.sendForm(serviceId, "template_att1chp", form.current, publicKey)
        .then((result) => {
            console.log(result.text);
            setIsEmailSent(true); // set email sent to true on success
            setIsLoading(false); // set loading to false on success
        }, (error) => {
            console.log(error.text);
            setErrorMessage(t('errors-contacts-not-sent')); // update error message on failure
            setIsLoading(false); // set loading to false on failure
        });
    } else {
      setErrorMessage(t('errors-contacts-empty-input')); // update error message
    }
  };

  if (isLoading) { // render loading screen if loading is true
    return (
      <div className="fixed inset-0 bg-white flex items-center justify-center">
        <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zM20 12a8 8 0 01-8 8v-4a4 4 0 004-4h4zm-2-7.938A7.962 7.962 0 0120 12h4c0-3.042-1.135-5.824-3-7.938l-3 2.647z" />
        </svg>
        <span className="text-lg">{t('contacts-form-sending')}</span>
      </div>
    );
  }

  if (isEmailSent) {
    return (
      <div className="isolate bg-white py-24 px-6 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <SuccessMessage message={t('contacts-message-sent')} />
        </div>
      </div>
    );
  }

  return (
    <div className="relative isolate bg-white">
      <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
        <div className="relative px-6 pt-24 pb-20 sm:pt-32 lg:static lg:py-48 lg:px-8">
          <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
            <div className="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden bg-gray-100 ring-1 ring-gray-900/10 lg:w-1/2">
              <svg
                className="absolute inset-0 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                    width={200}
                    height={200}
                    x="100%"
                    y={-1}
                    patternUnits="userSpaceOnUse"
                  >
                    <path d="M130 200V.5M.5 .5H200" fill="none" />
                  </pattern>
                </defs>
                <rect width="100%" height="100%" strokeWidth={0} fill="white" />
                <svg x="100%" y={-1} className="overflow-visible fill-gray-50">
                  <path d="M-470.5 0h201v201h-201Z" strokeWidth={0} />
                </svg>
                <rect width="100%" height="100%" strokeWidth={0} fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" />
              </svg>
            </div>
            <h2 className="text-3xl font-bold tracking-tight text-gray-900"  data-testid="main-contacts-header">{t('contacts-header')}</h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              {t('contacts-questions-text')}
            </p>
            <dl className="mt-10 space-y-4 text-base leading-7 text-gray-600">
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">{t('contacts-questions-address')}</span>
                  <BuildingOffice2Icon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                </dt>
                <dd>
                {t('contacts-questions-country-text')}
                  <br />
                  {t('contacts-questions-address-text')}
                </dd>
              </div>
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">{t('contacts-questions-phone')}</span>
                  <PhoneIcon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                </dt>
                <dd>
                  <div>
                    <a className="hover:text-gray-900" href="tel:+77077611028">
                      {t('contacts-questions-phone-text')}
                    </a>
                  </div>
                </dd>
              </div>
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">{t('contacts-questions-email')}</span>
                  <EnvelopeIcon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                </dt>
                <dd>
                  <div>
                    <a className="hover:text-gray-900" href="mailto:rocket.ltd.23@mail.ru">
                      {t('contacts-questions-email-text')}
                    </a>
                  </div>
                </dd>
              </div>
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">{t('contacts-questions-whatsapp')}</span>
                  <ChatBubbleBottomCenterTextIcon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                </dt>
                <dd>
                  <div>
                    <a className="hover:text-gray-900 hover:underline" href="https://wa.me/77477035272">
                      <span className="underline inline-block align-middle">{t('contacts-questions-whatsapp-text')}</span>
                    </a>
                  </div>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <form ref={form} onSubmit={sendEmail} className="px-6 pb-24 pt-20 sm:pb-32 lg:py-48 lg:px-8">
      {errorMessage && (
          <ErrorMessage message={errorMessage} />
        )}
          <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
            <div className="grid grid-cols-1 gap-y-6 gap-x-8 sm:grid-cols-2">
              <div>
                <label
                  htmlFor="first-name"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  {t('contacts-form-first-name')}
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    name="first-name"
                    id="first-name"
                    autoComplete="given-name"
                    required
                    className="block w-full rounded-md border-0 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="last-name"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  {t('contacts-form-last-name')}
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    autoComplete="family-name"
                    required
                    className="block w-full rounded-md border-0 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="company"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  {t('contacts-form-organization')}
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    name="company"
                    id="company"
                    autoComplete="organization"
                    required
                    className="block w-full rounded-md border-0 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="email"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  {t('contacts-form-email')}
                </label>
                <div className="mt-2.5">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    required
                    autoComplete="email"
                    className="block w-full rounded-md border-0 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="phone-number"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  {t('contacts-form-phone')}
                </label>
                <div className="mt-2.5">
                  <InputMask
                    mask="+7(999)999-99-99"
                    maskChar="_"
                    type="tel"
                    name="phone-number"
                    id="phone-number"
                    value={phoneNumber}
                    onChange={handlePhoneNumberInput}
                    required
                    autoComplete="tel"
                    className="block w-full rounded-md border-0 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="message"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  {t('contacts-form-message')}
                </label>
                <div className="mt-2.5">
                  <textarea
                    name="message"
                    id="message"
                    rows={4}
                    className="block w-full rounded-md border-0 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    defaultValue={""}
                  />
                </div>
              </div>
              <div className="flex gap-x-4 sm:col-span-2">
                <div className="flex h-6 items-center">
                  <button
                    type="button"
                    className={`flex w-8 flex-none cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
                      isChecked ? "bg-indigo-600" : "bg-gray-200"
                    }`}
                    role="switch"
                    aria-checked={isChecked}
                    aria-labelledby="switch-1-label"
                    onClick={() => setIsChecked(!isChecked)}
                  >
                    <span className="sr-only">{t('contacts-form-agreement')}</span>
                    <span
                      aria-hidden="true"
                      className={`h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out ${
                        isChecked ? "translate-x-3.5" : "translate-x-0"
                      }`}
                    />
                  </button>
                </div>
                <label className="text-sm leading-6 text-gray-600" id="switch-1-label">
                  {t('contacts-data-processing-agreement')}
                  <Link to="/privacyAgreement" className="font-semibold text-indigo-600" target = '_blank'>
                    {t('contacts-privacy-policy-link')}
                  </Link>
                  .
                </label>
              </div>
            </div>
            <div className="mt-8 flex justify-end">
              <button
                type="submit"
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {t('contacts-form-send')}
              </button>
            </div>
          </div>
        </form>
      </div>
      <CookieBanner />
    </div>
  )
}
