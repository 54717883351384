import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CookieBanner from '../components/CookieBanner';

const PrivacyAgreement = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <p className="text-base font-semibold leading-7 text-indigo-600">
            {t('privacy-agreement-subtitle')}
          </p>
          <h2 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
            {t('privacy-agreement-title')}
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            {t('privacy-agreement-section-2')}
          </p>
          <h3 className="mt-10 text-2xl font-bold tracking-tight text-gray-900">
            {t('privacy-agreement-subtitle-1')}
          </h3>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            {t('privacy-agreement-section-3')}
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            {t('privacy-agreement-section-4')}
          </p>
          <h3 className="mt-10 text-2xl font-bold tracking-tight text-gray-900">
            {t('privacy-agreement-subtitle-2')}
          </h3>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            {t('privacy-agreement-section-5')}
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            {t('privacy-agreement-section-6')}
          </p>
          <h3 className="mt-10 text-2xl font-bold tracking-tight text-gray-900">
            {t('privacy-agreement-subtitle-3')}
          </h3>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            {t('privacy-agreement-section-7')}
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            {t('privacy-agreement-section-8')}
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link
              to="/"
              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {t('privacy-agreement-button-1')}
            </Link>
            <Link
              to="/contacts"
              className="text-sm font-semibold text-gray-900"
            >
              {t('privacy-agreement-button-2')}{' '}
              <span aria-hidden="true">&rarr;</span>
            </Link>
          </div>
        </div>
      </div>
      <CookieBanner />
    </div>
  );
};

export default PrivacyAgreement;
