import { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSpinner } from "@fortawesome/free-solid-svg-icons";
import WaybillDetails from "../components/WaybillDetails";
import TrackingFeed from "../components/TrackingFeed";
import DeliveryDetails from "../components/DeliveryDetails";
import CookieBanner from '../components/CookieBanner';
import ErrorMessage from "../components/ErrorMessage";
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

function Tracking() {
  const { t } = useTranslation();
  const inputData = useParams().inputData; // Get the waybill code from the URL
  const [waybillCode, setWaybillCode] = useState(inputData || "");
  const [trackingData, setTrackingData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = useCallback(async (event) => {
    if (event) {
      event.preventDefault();
    }
    if (!waybillCode) {
      setError(t("errors-waybill-empty-input"))
      return;
    }
    setIsLoading(true);
    try {
      const response = await fetch(`https://rocket.dry-nomads.com/api/public/v1/tracking/${waybillCode}`);
      const data = await response.json();
      if (data.error === "Waybill does not exist") {
        setError(t("errors-waybill-not-found"));
        setTrackingData(null);
      } else {
        setTrackingData(data);
        setError(null);
      }
    } catch (error) {
      console.log(error);
      setError(t("errors-api-error"));
    }
    setIsLoading(false);
  }, [waybillCode, t]);  

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 5000); // 5 seconds
    }
    if (inputData) {
      handleSearch(null, inputData);
    }
  }, [error, inputData, handleSearch]);  
      
  const renderTrackingDetails = () => {
    if (!trackingData || !trackingData.destination || !trackingData.destination.destination_tracks || trackingData.destination.destination_tracks.length === 0) {
      console.log(t("errors-waybill-tracks-not-found"));
      return null;
    }
    return (
      <div>
        <WaybillDetails info={trackingData.destination} />
        <DeliveryDetails deliveries={trackingData.destination.deliveries} />
        <TrackingFeed tracks={trackingData.destination.destination_tracks} />
      </div>
    );
  };

  return (
    <div className="max-w-screen mx-auto sm:px-6 lg:px-6">
      <div className="container mx-auto px-4 py-8">
        <div className="relative mt-8 flex items-center justify-center">
          <div className="w-full md:w-1/2 lg:w-1/2">
            <form onSubmit={handleSearch}>
              <div className="flex items-center justify-center mt-10">
              <input
                type="text"
                className="w-1/2 px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-blue-400"
                placeholder={t("tracking-waybill-input")}
                value={waybillCode}
                onFocus={(event) => event.target.select()}
                onChange={(e) => setWaybillCode(e.target.value)}
                required
              />
                <button
                  className="ml-2 px-4 py-2 bg-blue-500 text-white rounded-lg"
                  onClick={handleSearch}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    <FontAwesomeIcon icon={faSearch} />
                  )}
                </button>
              </div>
            </form>
            {trackingData ? (
              <div className="mt-4">
                {renderTrackingDetails()}
              </div>
            ) : (
              <div>
                {error !== null && trackingData === null && (
                  <ErrorMessage message={error} />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <CookieBanner />
    </div>
  );
}

export default Tracking;
