import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const CookieBanner = () => {
  const { t } = useTranslation();
  const [acceptedCookies, setAcceptedCookies] = useState(false);

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem('cookiesAccepted');
    if (cookiesAccepted === 'true') {
      setAcceptedCookies(true);
    }
  }, []);  

  const handleAcceptCookies = () => {
  localStorage.setItem('cookiesAccepted', 'true');
  setAcceptedCookies(true);
  };


  return (
    <div className={`${acceptedCookies ? 'hidden' : 'block'}`}>
      <div className={`cookie-banner bg-gray-800 fixed bottom-0 left-0 w-full z-10  sm:flex sm:items-center sm:justify-between`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-3 flex flex-col sm:flex-row sm:items-center">
          <p className="ml-3 font-medium text-white mb-2 sm:mb-0">
            {t('cookie-banner-text')}
          </p>
          <button
            onClick={handleAcceptCookies}
            className="cookie-banner__button bg-indigo-500 hover:bg-indigo-600 px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ml-0 sm:ml-4"
          >
            {t('cookie-banner-accept')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieBanner;
