import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/rocket-logo.svg";
import { useTranslation } from 'react-i18next';

function Header() {
  const { t } = useTranslation();
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  function toggleMobileMenu() {
    setShowMobileMenu(!showMobileMenu);
  }

  function closeMobileMenu() {
    setShowMobileMenu(false);
  }

  return (
    <div className="bg-white">
      <header className="absolute inset-x-0 top-0 z-50">
        <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
          <div className="flex lg:flex-1">
          <Link to="/">
            <img src={logo} alt="Logo" className="h-14 w-auto"/>
          </Link>
          </div>
          <div className="flex lg:hidden">
            <button type="button" className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700" onClick={toggleMobileMenu}>
              <span className="sr-only">{showMobileMenu ? "Close" : "Open"} main menu</span>
              <svg className={`h-6 w-6 ${showMobileMenu ? "hidden" : "block"}`} fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16"/>
              </svg>
              <svg className={`h-6 w-6 ${showMobileMenu ? "block" : "hidden"}`} fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
              </svg>
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            <Link to="/" onClick={closeMobileMenu} data-testid="main-menu-home-link" className="text-sm font-semibold leading-6 text-gray-900">
              {t('header-main')}
            </Link>

            <Link to="/tracking" onClick={closeMobileMenu} data-testid="main-menu-tracking-link" className="text-sm font-semibold leading-6 text-gray-900">
              {t('header-tracking')}
            </Link>

            <Link to="/contacts" onClick={closeMobileMenu} data-testid="main-menu-contacts-link" className="text-sm font-semibold leading-6 text-gray-900">
              {t('header-contacts')}
            </Link>
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <Link to="https://rocket.dry-nomads.com/cabinet" onClick={closeMobileMenu} data-testid="main-menu-cabinet-link" className="text-sm font-semibold leading-6 text-gray-900" target = '_blank'>
              {t('header-cabinet')} <span aria-hidden="true">&rarr;</span>
            </Link>
          </div>
        </nav>
        {/* Mobile menu, show/hide based on menu open state. */}
        <div className={`lg:hidden ${showMobileMenu ? "block" : "hidden"}`} role="dialog" aria-modal="true">
          {/* Background backdrop, show/hide based on slide-over state. */}
          <div className="fixed inset-0 z-50"></div>
          <div className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
            <Link to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Rocket Ltd.</span>
              <img className="h-8 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" alt=""/>
            </Link>
              <button type="button" className="-m-2.5 rounded-md p-2.5 text-gray-700" onClick={toggleMobileMenu}>
                <span className="sr-only">{t('header-close-menu')}</span>
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                </svg>
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  <Link to="/" onClick={closeMobileMenu} id="main-mobile-menu-home-link" className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                    {t('header-main')}
                  </Link>

                  <Link
                    to="/tracking" onClick={closeMobileMenu} id="main-mobile-menu-tracking-link"
                    className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                    {t('header-tracking')}
                  </Link>

                  <Link to="/contacts" onClick={closeMobileMenu} id="main-mobile-menu-contacts-link"
                    className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                    {t('header-contacts')}
                  </Link>
                </div>
                <div className="py-6">
                  <Link to="/cabinet" onClick={closeMobileMenu} id="main-mobile-menu-cabinet-link"
                    className="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                    {t('header-cabinet')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;
